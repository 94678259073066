import { propertySVGs } from 'utils/helpers';
import { ReactComponent as BedSVG } from 'icons/custom/bed.svg';
import { ReactComponent as CalendarSVG } from 'icons/custom/calendar.svg';
import { ReactComponent as TagSVG } from 'icons/custom/tag.svg';
import { ReactComponent as ChartSVG } from 'icons/custom/chart.svg';
import { ReactComponent as BackwardSVG } from 'icons/custom/backward.svg';
import { ReactComponent as HouseSVG } from 'icons/custom/house.svg';
import { ReactComponent as BankSVG } from 'icons/custom/bank.svg';
import { ReactComponent as PackageSVG } from 'icons/custom/package.svg';
import { ReactComponent as UploadSVG } from 'icons/custom/upload.svg';
import { ReactComponent as GardenSVG } from 'icons/custom/garden.svg';
import { ReactComponent as FlameSVG } from 'icons/custom/flame.svg';
import { ReactComponent as LocationSVG } from 'icons/custom/location-blue.svg';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import moment from 'moment';
import { demographicsInfoData } from '../../../../utils/helpers';
import InfoAsterisk from '../../Dashboard/Components/InfoAsterisk';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

export const getPropertyParameters = (property) => [
  {
    name: 'Number of Bedrooms',
    icon: <BedSVG />,
    value: property?.property_index_data?.number_of_bedrooms,
  },
  {
    name: 'Operating Income',
    icon: <BankSVG />,
    value: numeral(property?.operating_income).format(),
  },
  {
    name: 'Last Transaction Date',
    icon: <CalendarSVG />,
    value: moment(property?.property_index_data?.last_transaction_date).isBefore(moment())
      ? property?.property_index_data?.last_transaction_date?.split(/-/)?.reverse()?.join('/')
      : undefined,
  },
  {
    name: 'Last Transaction Price',
    icon: <BackwardSVG />,
    value:
      property?.property_index_data?.last_transaction_price > 0
        ? numeral(property?.property_index_data?.last_transaction_price).format()
        : undefined,
  },
  {
    name: 'Index AVM value',
    icon: <TagSVG />,
    value: numeral(property?.price).format(),
  },
  {
    name: 'Index Rental AVM',
    icon: <TagSVG />,
    value: property?.operating_income ? numeral(property?.operating_income / 12).format() : undefined,
  },
  {
    name: 'Average Listed Price',
    icon: <ChartSVG />,
    value: property?.listed_average_price ? numeral(property?.listed_average_price).format() : undefined,
  },
  {
    name: 'Property Type',
    icon: <HouseSVG />,
    value: propertySVGs[property?.property_index_data?.property_type]?.name,
  },
  {
    name: 'Debt Outstanding',
    icon: <BankSVG />,
    value: numeral(property?.debt).format(),
  },
  {
    name: 'Occupancy',
    icon: <PackageSVG />,
    value: numeral(property?.occupancy / 100).format('%'),
  },
  {
    name: 'Monthly Expenses',
    icon: <UploadSVG />,
    value: property?.operating_income ? numeral((property?.operating_income / 12) * 0.4).format() : undefined,
  },
  {
    name: 'EPC',
    icon: <FlameSVG />,
    value: property.changed_epc ? property.changed_epc : property?.epc !== null ? property?.epc : undefined,
  },
  {
    name: 'Garden',
    icon: <GardenSVG />,
    value: property?.garden_count !== null ? (property?.garden_count === 0 ? ' No' : 'Yes') : undefined,
  },
  {
    name: 'Construction Date',
    icon: <CalendarSVG />,
    value: property?.building_build_date ? property?.building_build_date : undefined,
  },
  {
    name: 'New Building',
    icon: <HouseSVG />,
    value: property?.derived_new_build !== null ? (property?.derived_new_build === 'N' ? 'No' : 'Yes') : undefined,
  },
  {
    name: 'New Building',
    icon: <HouseSVG />,
    value: property?.derived_new_build !== null ? (property?.derived_new_build === 'N' ? 'No' : 'Yes') : undefined,
  },
  {
    name: 'UPRN',
    icon: <LocationSVG className="w-6 h-6" />,
    value: property.original_uprn ? property.original_uprn : property.uprn,
  },
];

export const getPropertyDemographicsParameters = (demographicsData) => {
  if (!demographicsData || !demographicsInfoData) {
    return [];
  }

  const { demographics, district_code } = demographicsData;

  return demographics
    .filter((item) => item?.feature_name && demographicsInfoData.hasOwnProperty(item.feature_name))
    .map((parameter) => {
      const info = demographicsInfoData[parameter.feature_name];
      const value = parameter.feature_value ? (
        numeral(parameter.feature_value).format('0')
      ) : (
        <span className="italic opacity-50"> - </span>
      );
      const icon = <ChartSVG />;
      const popupTitle = demographicsInfoData[parameter?.feature_name]?.info.replace(
        'district_code',
        demographicsData?.district_code,
      );
      // const popupDescription = `Min: ${numeral(demographicsInfoData[parameter?.feature_name]?.min).format('0')},
      // Max: ${numeral(demographicsInfoData[parameter?.feature_name]?.max).format('0')}`;

      return {
        name: demographicsInfoData[parameter?.feature_name]?.title,
        icon: icon,
        value: value,
        note: info?.info.replace('district_code', district_code)
          ? `Min: ${numeral(info.min).format('0')}, Max: ${numeral(info.max).format('0')}`
          : undefined,
        popupTitle: popupTitle,
        // popupDescription: popupDescription,
      };
    });
};

export const getPropertyDashboardParams = (property, premiumValue, premiumValueLoading = true) => [
  {
    title: 'Index AVM value',
    value: property?.price ? numeral(property?.price).format() : undefined,
    info: renderAccuracyInfo({ property }),
    pdfInfo: renderAccuracyText({ property }),
  },
  {
    title: 'Top 15% Premium Properties',
    value:
      premiumValue?.premium_properties_value > 0 ? numeral(premiumValue?.premium_properties_value).format() : undefined,
    loading: premiumValueLoading,
  },
  {
    title: 'Index Rental AVM',
    value: property?.operating_income ? numeral(property?.operating_income / 12).format() : undefined,
  },
  {
    title: 'Asset Return (Last 5 Years)',
    value: numeral(property?.asset_return).format('0.00%'),
  },
  {
    title: 'Gross Yield',
    value:
      property?.operating_income / property?.price
        ? numeral(property?.operating_income / property?.price).format('0.00%')
        : undefined,
  },
  {
    title: 'Cap Rate',
    value:
      property?.operating_income / property?.price
        ? numeral((property?.operating_income / property?.price) * 0.6).format('0.00%')
        : undefined,
  },
];

export const getKeyPerformanceMetrics = (itemPM, benchmarkPM) => [
  {
    metric: 'Risk-free-rate',
    itemName: itemPM?.riskFreeRate !== undefined ? numeral(itemPM?.riskFreeRate).format('0.00%') : ' - ',
    benchmark: benchmarkPM?.riskFreeRate !== undefined ? numeral(benchmarkPM?.riskFreeRate).format('0.00%') : ' - ',
  },
  {
    metric: 'Cumulative Return (Past 5 years)',
    itemName: numeral(itemPM?.cumulativeReturn).format('0.00%'),
    benchmark: numeral(benchmarkPM?.cumulativeReturn).format('0.00%'),
  },
  {
    metric: 'CAGR% (Past 5 years)',
    itemName: numeral(itemPM?.cagr).format('0.00%'),
    benchmark: numeral(benchmarkPM?.cagr).format('0.00%'),
  },
  {
    metric: 'Sharpe (Past 12 months)',
    itemName: itemPM?.riskFreeRate !== undefined ? numeral(itemPM?.sharpe).format('0.00') : ' - ',
    benchmark: benchmarkPM?.riskFreeRate !== undefined ? numeral(benchmarkPM?.sharpe).format('0.00') : ' - ',
  },
  {
    metric: 'One month return',
    itemName: itemPM?.oneMonthReturn,
    benchmark: benchmarkPM?.oneMonthReturn,
  },
  {
    metric: 'YTD returns',
    itemName: numeral(itemPM?.YTDReturns).format('0.00%'),
    benchmark: numeral(benchmarkPM?.YTDReturns).format('0.00%'),
  },
  {
    metric: 'Last 12 months returns',
    itemName: numeral(itemPM?.last12MonthReturns).format('0.00%'),
    benchmark: numeral(benchmarkPM?.last12MonthReturns).format('0.00%'),
  },
  {
    metric: 'Expected 12 months returns',
    itemName: itemPM?.expected12MonthReturns,
    benchmark: benchmarkPM?.expected12MonthReturns,
  },
  {
    metric: 'Expected 24 months returns',
    itemName: itemPM?.expected24MonthReturns,
    benchmark: benchmarkPM?.expected24MonthReturns,
  },
  {
    metric: 'Estimated Sale Value',
    itemName: numeral(itemPM?.estimatedSaleValue).format(),
    benchmark: numeral(benchmarkPM?.estimatedSaleValue).format(),
  },
  {
    metric: 'Volatility',
    itemName: numeral(itemPM?.volatility).format('0.00%'),
    benchmark: numeral(benchmarkPM?.volatility).format('0.00%'),
  },
];

export const renderAccuracyInfo = ({ property }) => {
  if (moment(property?.property_index_data?.last_transaction_date, 'YYYY-MM-DD').isValid()) {
    const yearsAfterLastTransaction = moment().diff(
      moment(property?.property_index_data?.last_transaction_date, 'YYYY-MM-DD'),
      'years',
    );

    if (yearsAfterLastTransaction < 5 && yearsAfterLastTransaction >= 0) {
      return <InfoAsterisk color="text-green-500" text="High accuracy: between 1% - 5% of actual price" />;
    } else {
      return <InfoAsterisk color="text-yellow-500" text="Medium accuracy: between 5% - 10% of actual price" />;
    }
  } else {
    return null;
  }
};

export const renderAccuracyText = ({ property }) => {
  if (moment(property?.property_index_data?.last_transaction_date, 'YYYY-MM-DD').isValid()) {
    const yearsAfterLastTransaction = moment().diff(
      moment(property?.property_index_data?.last_transaction_date, 'YYYY-MM-DD'),
      'years',
    );

    if (yearsAfterLastTransaction < 5 && yearsAfterLastTransaction >= 0) {
      return '(High accuracy: between 1% - 5% of actual price)';
    } else {
      return '(Medium accuracy: between 5% - 10% of actual price)';
    }
  } else {
    return null;
  }
};
